<template>
    <v-sheet flat v-if="user">
        <h3 class="mb-2">
            {{user.name}}
        </h3>
        <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel v-for="(field, i) in fields" :key="i">
                <v-expansion-panel-header>{{field.name}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <profile-field :id="field.id" :user="id"></profile-field>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-sheet>
</template>

<script>
    import ProfileField from "@c/profiles/ProfileField";

    export default {
        name: "EditUserProfileFields",
        props: {
            id: {type: String}
        },
        data: () => {
            return {
                panel: []
            }
        },
        computed: {
            user() {
                return this.$store.state.users.data[this.id];
            },
            fields(){
                return this.$store.state.profileFields.data;
            }, 
        },
        components: {
            ProfileField
        },
        created(){
            this.$store.dispatch("users/fetchById", this.id );
            this.$store.dispatch("profileFields/fetchAndAdd");
            this.$store.dispatch("profileFieldValues/fetchUserValues", this.id )
        }
    }
</script>