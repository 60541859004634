<template>
    <v-simple-table>
        <tr v-for="(value, i) in values" :key="value.id" :class="{'secondary--background': i % 2 == 0 }">
            <td class="pa-2">
                <span v-if="value.question">
                    {{questionText(value.question)}}
                </span>
            </td>
            <td class="pa-2">
                <span v-if="value.question">
                    {{answerText(value.question, value.value)}}
                </span>
                <span v-else>
                    {{value.value}}
                </span>
            </td>
            <td class="pa-2">
                {{value.created_at.toDate().toLocaleDateString()}}
            </td>

        </tr>
    </v-simple-table>
</template>

<script>
    export default {
        name: "ProfileField",
        props: {
            id: {type: String},
            user: {type: String}, 
        },
        computed: {
            values(){
                return this.$store.getters["profileFieldValues/getByUserAndId"]({id: this.id, user: this.user })
            }, 
        },
        methods: {
            questionText(q){
                return this.$store.getters["questions/title"](q)
            },
            answerText(q, v){
                return this.$store.getters["questions/parseValueToString"](q,v)
            }
        }   
    }
</script>